export const devFirebaseConfig = {
  apiKey: "AIzaSyB44M55Rrz8BQW0aYgNtMO_EfAF9gGCwsI",
  authDomain: "redprint-website-test.firebaseapp.com",
  projectId: "redprint-website-test",
  storageBucket: "redprint-website-test.appspot.com",
  messagingSenderId: "575480490793",
  appId: "1:575480490793:web:38bc633eec0e4234a76538",
  measurementId: "G-ZZCM3V6G6S",
};

export const prodFirebaseConfig = {
  apiKey: "AIzaSyCillixmUZ4DhDLO2VO5r1BJEIdQFyMGVo",
  authDomain: "redprint-ios-79ec5.firebaseapp.com",
  databaseURL: "https://redprint-ios-79ec5.firebaseio.com",
  projectId: "redprint-ios-79ec5",
  storageBucket: "redprint-ios-79ec5.appspot.com",
  messagingSenderId: "1065522909288",
  appId: "1:1065522909288:web:86f796690f68b66b8eabdd",
  measurementId: "G-L7QH92HJJW",
};
