import { useState } from "react";
import axios from "axios";
import ProgressBar from "@ramonak/react-progress-bar";
import { useParams } from "react-router-dom";

const VideoUpload = (props) => {
  const [videoFile, setVideoFile] = useState(null);
  const [fileupload, setFileUpload] = useState(null);
  const [isClicked, setCLicked] = useState(false);

  const exerciseId = window.location.pathname.split("/")[2];

  console.log(exerciseId);

  console.log("VideoUpload", props);

  const uploadVideo = async (videoFile, gymId, token, userId, roleId) => {
    // Compress the video file first
    setCLicked(true);
    const formData = new FormData();
    formData.append("video", videoFile);

    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    try {
      const response = await axios.post(
        API_ENDPOINT +
          `/exercise/videos/${gymId}/${exerciseId}/upload/${userId}/${roleId}`,
        formData,
        {
          headers: { Authorization: `Bearer ${token}` },
          onUploadProgress: (progressEvent) => {
            const percentageComplete = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setFileUpload({ fileName: videoFile.name, percentageComplete });
          },
        }
      );

      if (response.status === 200) {
        alert("Video uploaded successfully");
        props.setShowPopup(false);
      } else {
        alert("Failed to upload video");
      }
    } catch (error) {
      console.error("Upload error:", error);
      alert("Failed to upload video");
    }
    setCLicked(false);
    props.videoService.getExerciseList(props.gymId);
  };

  const handleChange = (event) => {
    setVideoFile(event.target.files[0]);
  };

  return (
    <div
      className="popup fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50 z-10"
      onClick={() => props.setShowPopup(false)}
    >
      <div
        className="popup-content bg-white p-8 rounded shadow-lg max-w-md m-auto"
        onClick={(e) => e.stopPropagation()}
      >
        <h2 className="text-2xl mb-4">Select Video File</h2>
        <input
          type="file"
          onChange={handleChange}
          className="mb-4"
          name="videoFile"
          accept="video/*"
        />
        {fileupload && (
          <ProgressBar
            completed={fileupload.percentageComplete}
            bgColor="#5B0068"
            baseBgColor="#e0e0de"
            labelColor="#5B0068"
          />
        )}
        <br />
        <button
          onClick={(event) => {
            //event.stopPropagation();
            event.preventDefault();
            uploadVideo(
              videoFile,
              props.gymId,
              props.token,
              props.userId,
              props.roleId
            );
          }}
          className="text-white px-4 py-2 rounded mr-2"
          style={{ backgroundColor: isClicked ? "#e0e0de" : "#5B0068" }}
          disabled={isClicked}
        >
          Submit
        </button>
        <button
          onClick={() => props.setShowPopup(false)}
          className="bg-red-500 text-white px-4 py-2 rounded"
          style={{ backgroundColor: isClicked ? "#e0e0de" : "#FF0000" }}
          disabled={isClicked}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default VideoUpload;
