import React, { useState, useEffect, useContext } from "react";
import ExpandableButton from "./Schedule";
import Button from "./button";
import Toast from "../../common/popup";
import Modal from "./modal";
import { AppContext } from "../../App";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getData, deleteData, addData, updateData } from "../../common/utils";
import moment from "moment-timezone";
//import { collection, addDoc, getFirestore, getDocs,doc, updateDoc } from "firebase/firestore";
import Alert from "../Settings/Alert";
import { Timestamp } from "firebase/firestore";
const CreateMessage = ({ isOpen, onClose, selectedGyms:initialSelectedGyms }) => { 
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const [templates, setTemplates] = useState([]);
  const [selectedTemplateId, setSelectedTemplateId] = useState(null);
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [link, setLink] = useState("");
  const [originalSubject, setOriginalSubject] = useState("");
  const [originalMessage, setOriginalMessage] = useState("");
  const [originalLink, setOriginalLink] = useState("");
  const [initialLink, setInitialLink] = useState(link);
  const [popupMessage, setPopupMessage] = useState("");
  const [scheduledDate, setScheduledDate] = useState("");
  const [scheduledTime, setScheduledTime] = useState("");
  const [showScheduleSend, setShowScheduleSend] = useState(false);
  const [showMessageSentPopup, setShowMessageSentPopup] = useState(false);
  const [showScheduleModal, setShowScheduleModal] = useState(false);
  const { appInfo } = useContext(AppContext);
  const [initialMessage, setInitialMessage] = useState("");
  const [showDateEntryError, setShowDateEntryError] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertColor, setAlertColor] = useState("green"); 
  const [showNoChangesAlert, setShowNoChangesAlert] = useState(false);
  // State Definitions
  const [selectedGyms, setSelectedGyms] = useState(initialSelectedGyms);
  useEffect(() => {
    setSelectedGyms(initialSelectedGyms);
  }, [initialSelectedGyms]);

  // Track initial values of subject, message, and link
  useEffect(() => {
    setOriginalSubject(subject);
    setOriginalMessage(message);
    setOriginalLink(link);
  }, [selectedTemplateId, templates]);

  useEffect(() => {
    const fetchTemplates = async () => {
      try {
        const org_id = appInfo.org.org_id;
        const templatesData = await getData(
          `notifications/templates?org_id=${org_id}`,
          appInfo.token
        );
        //console.log("Templates:", templatesData);
        setTemplates(templatesData);
      } catch (error) {
        showAlertMessage("Error fetching templates. Please try again later.", "red");
      }
    };
    fetchTemplates();
  }, [appInfo.token, showMessageSentPopup]);

  useEffect(() => {
    if (selectedTemplateId !== null) {
      const selectedTemplate = templates.find(
        (template) => template.id === selectedTemplateId
      );
      if (selectedTemplate) {
        setSubject(selectedTemplate.subject);
        setMessage(selectedTemplate.message);
        setLink(selectedTemplate.link || "");
        setInitialMessage(selectedTemplate.message);
        setOriginalSubject(selectedTemplate.subject);
        setOriginalMessage(selectedTemplate.message);
        setOriginalLink(selectedTemplate.link || "");
      }
    }
  }, [selectedTemplateId, templates]);

  const handleDeleteTemplate = async () => {
    if (selectedTemplateId === null) {
      showAlertMessage("Please select a template to delete.", "red");
      return;
    }

    if (selectedTemplateId === 1) return;

    try {
      await deleteData(
        `notifications/template/${selectedTemplateId}`,
        appInfo.token
      );
      setTemplates((prev) => prev.filter((t) => t.id !== selectedTemplateId));
      setSelectedTemplateId(null);
      showAlertMessage("Template deleted successfully!", "green");
    } catch (error) {
      showAlertMessage(`Error deleting template: ${error.message}`, "red");
    } finally {
      setShowMessageSentPopup(true);
      setTimeout(() => setShowMessageSentPopup(false), 3000);
    }
  };

  const selectTemplate = (template) => {
    setSelectedTemplateId(template.id);
  };

  const isMessageEdited = () => {
    return subject !== originalSubject || message !== originalMessage || link !== originalLink;
  };

  const handleSaveClick = () => {
    if (!subject && !message ) {
      showAlertMessage("Please enter subject,message or link to save the template.", "red");
      return;
    }
  
    if (!isMessageEdited()) {
      setShowNoChangesAlert(true);
      return;
    }

    handleSaveTemplate();
  };
  
  const handleScheduleSendClick = async () => {
    const dateTime = `${scheduledDate} ${scheduledTime}`;
    if (!selectedGyms || selectedGyms.length === 0) {
      showAlertMessage("Please select at least one gym before scheduling the message.", "red");
      return;
    }
    
    if (!scheduledDate || !scheduledTime) {
      showAlertMessage("Please enter date and time.", "red");
      return;
    }
  
    const selectedDateTime = moment.tz(dateTime, userTimeZone);
    const currentTime = moment.tz(userTimeZone);
  
    if (selectedDateTime.isBefore(currentTime)) {
      showAlertMessage("The scheduled date/time must be in the future.", "red");
      return;
    }
  
    setShowScheduleModal(false);
    setShowScheduleSend(false);
  
    const scheduledTimeUTC = selectedDateTime.utc().format();
    const receiver_info = JSON.stringify(
      Array.isArray(selectedGyms) && selectedGyms.length > 0 ? selectedGyms : [-1]
    );
  
    const newNotification = {
      subject: subject,
      message: message,
      link: link,
      scheduled_at: scheduledTimeUTC,
      dateTime: new Date(dateTime).toISOString(),
      receiver_info: receiver_info,
      user_id: appInfo.userInfo.u_id,
      org_id: appInfo.org.org_id,
    };
  
    try {
      const responseData = await addData(
        "notifications/sendSchedulenotification",
        JSON.stringify(newNotification),
        appInfo.token
      );
      showAlertMessage("Notification scheduled successfully!", "green");
      setTimeout(() => {
        onClose();
        window.location.reload();
      }, 2500);
    } catch (error) {
      showAlertMessage(`Error scheduling notification: ${error.message}`, "red");
    }
  };
//   const handleScheduleSendClick = async () => {
//     const dateTime = `${scheduledDate} ${scheduledTime}`;
//     if (scheduledDate && scheduledTime) {
//         setShowScheduleModal(false);
//         setShowScheduleSend(false);

//         const scheduledTimeUTC = moment.tz(dateTime, userTimeZone).utc().format();
//         const receiver_info = JSON.stringify(Array.isArray(selectedGyms) && selectedGyms.length > 0 ? selectedGyms : [-1]);

//         const newNotification = {
//             subject: subject,
//             message: message,
//             link: link,
//             scheduled_at: scheduledTimeUTC, // ISO string
//             dateTime: new Date(dateTime).toISOString(), 
//             receiver_info: receiver_info,
//             user_id: appInfo.userInfo.u_id,
//             org_id: appInfo.org.org_id,
//         };

//         try {
//             const responseData = await addData(
//                 "notifications/sendSchedulenotification",
//                 JSON.stringify(newNotification),
//                 appInfo.token
//             );
//             showAlertMessage("Notification scheduled successfully!", "green");
//             setTimeout(() => {
//               onClose();
//               window.location.reload();
//           }, 2500);
//         } catch (error) {
//           showAlertMessage(`Error scheduling notification: ${error.message}`, "red");
//         }
//     } else {
//       showAlertMessage("Please enter date and time", "red");
//     }
// };

const handleSaveTemplate = async () => {
  if (!subject || !message) {
    showAlertMessage("Please enter subject and message to save the template.", "red");
    return;
  }
  const timestamp = new Date().toISOString();
  const templateDetails = { 
    subject: subject || "",  
    message: message || "",  
    link: link || "",
    org_id: appInfo.org.org_id,
    created_at: selectedTemplateId ? undefined : timestamp,
    updated_at: timestamp, };

  try {
    let responseData;

    if (!selectedTemplateId) {
      // Creating a new template
      responseData = await addData(
        "notifications/addtemplate",
        JSON.stringify(templateDetails),
        appInfo.token
      );
    } else {
      // Updating an existing template
      templateDetails.updated_at = timestamp;
      responseData = await updateData(
        `notifications/templates/${selectedTemplateId}`,
        JSON.stringify(templateDetails),
        appInfo.token
      );
    }

    showAlertMessage("Template saved successfully!", "green");

    // Close form after alert
    setTimeout(() => {
      onClose(); 
    }, 3000); 

  } catch (error) {
    console.error("Error saving template:", error);
    showAlertMessage(`Error saving template: ${error.message}`, "red");
  }
};


  const showAlertMessage = (message, color) => {
    setAlertMessage(message);
    setAlertColor(color);
    setShowAlert(true);
    setTimeout(() => setShowAlert(false), 2500);
  };

  if (!isOpen) return null;

  return (
    <div className="inset-0 z-50 bg-smoke-light flex w-full">
      <div className="bg-white w-[670px] max-w-none h-[538px] flex-col flex rounded-lg">
        <div className="flex">
          <div className="mt-8 w-[350px] h-[510px] text-sm font-medium shadow-md rounded-lg bg-gray-100 p-2 overflow-hidden">
            <h2 className="text-sm font-semibold text-gray-700 mb-3">Templates:</h2>
            <div className="overflow-y-auto max-h-[450px]">
              {templates.map((template) => (
                <div
                  key={template.id}
                  className={`cursor-pointer p-2 my-1 ${
                    selectedTemplateId === template.id
                      ? "bg-purple-300 rounded-lg"
                      : ""
                  }`}
                  onClick={() => selectTemplate(template)}
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    width: "100%",
                  }}
                >
                  {template.subject}
                </div>
              ))}
            </div>
          </div>
          <div className="p-3">
            <div className="mb-2 ">
              <label className="mt-8 block text-gray-700 text-sm font-bold mb-2">
                Subject:
              </label>
              <input
                className="shadow text-sm appearance-none bg-gray-100 border rounded w-[450px] py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                style={{ textAlign: "Topleft" }}
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
              />
            </div>
            <div className="mb-2">
              <label className="block text-gray-700 text-sm font-bold mb-2">
                Message:
              </label>
              <textarea
                className="shadow text-sm appearance-none border rounded w-[450px] h-[250px] py-2 px-3 text-gray-700 text-sm leading-tight focus:outline-none focus:shadow-outline"
                style={{ textAlign: "Topleft" }}
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              ></textarea>
            </div>
            <div className="mb-2">
              <label className="block text-gray-700 text-sm font-bold mb-2">
                Link (optional):
              </label>
              <input
                type="url"
                className="shadow text-sm appearance-none border rounded w-[450px] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                placeholder="https://example.com"
                value={link}
                onChange={(e) => setLink(e.target.value)}
                pattern="https://.*"
                title="Please enter a valid URL that starts with https://"
              />
            </div>
            <div className="flex items-start justify-between">
              <div className="flex justify-start">
                <Button
                  class={`${
                    isMessageEdited()
                      ? "bg-purple-800 hover:bg-purple-600 text-white"
                      : "bg-gray-200 hover:bg-purple-400  text-black"
                  } text-sm font-medium py-2 px-2 p-1 rounded focus:outline-none focus:shadow-outline`}
                  value="Save template"
                  onClick={handleSaveClick}
                  disabled={!isMessageEdited()}
                />
                <button
                  className="text-black border-none bg-gray-200 rounded hover:bg-red-300 font-medium text-sm px-2 py-0.5"
                  onClick={() => handleDeleteTemplate(selectedTemplateId)}
                >
                  <FontAwesomeIcon icon={faTrash} />
                </button>
              </div>
              <ExpandableButton
                setShowScheduleSend={setShowScheduleSend}
                showScheduleSend={showScheduleSend}
                setShowMessageSentPopup={setShowMessageSentPopup}
                setPopupMessage={setPopupMessage}
                subject={subject}
                message={message}
                link={link}
                onClose={onClose}
                selectedGyms={selectedGyms}
                onGymsChange={(updatedGyms) => setSelectedGyms(updatedGyms)}
              />
              {showScheduleSend && (
                <Modal
                  modalHeader="Schedule Send"
                  toggleModal={() => {
                    setShowScheduleSend(false);
                    setScheduledDate("");
                    setScheduledTime("");
                  }}
                >
                  <div className="mt-2 w-[270px] mx-w-none bg-white  rounded px-4 py-3 mx-auto">
                    <div className="mb-4">
                      <label className="block text-gray-700 text-sm font-bold mb-2">
                        Pick date:
                      </label>
                      <input
                        type="date"
                        value={scheduledDate}
                        onChange={(e) => setScheduledDate(e.target.value)}
                        className="border rounded py-2 px-3 text-gray-700"
                      />
                    </div>
                    <div className="flex flex-col mb-4">
                      <label className="block text-gray-700 text-sm font-bold mb-2">
                        Pick time:
                      </label>
                      <input
                        type="time"
                        value={scheduledTime}
                        onChange={(e) => setScheduledTime(e.target.value)}
                        className="border rounded py-2 px-3 text-gray-700"
                      />
                    </div>
                    <div className="flex justify-between">
                      <div
                        onClick={() => {
                          setShowScheduleSend(false);
                          setScheduledDate("");
                          setScheduledTime("");
                        }}
                        className="bg-gray-100 hover:bg-gray-300 text-black text-sm font-medium py-2 px-4 rounded"
                      >
                        Cancel
                      </div>
                      <div
                        onClick={() => {
                          handleScheduleSendClick();
                          setShowScheduleModal(false);
                        }}
                        className="bg-purple-800 hover:bg-purple-600 text-sm text-white font-medium py-2 px-4 rounded"
                      >
                        Send Schedule
                      </div>
                    </div>
                  </div>
                </Modal>
              )}

            </div>
           
            {showAlert && (
              <Alert
                message={alertMessage}
                onClose={() => setShowAlert(false)}
                color={alertColor}
                timeout={2500}
              />
            )}
            {showNoChangesAlert && (
              <Alert
                message="No changes have been made to the template"
                color="red"
                onClose={() => setShowNoChangesAlert(false)}
                timeout={2500}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateMessage;