import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import MultiSelectDropdown from "../../common/multiSelectDropdown";
import { getData, addData, updateData } from "../../common/utils";
import {
  EmailAuthProvider,
  getAuth,
  updatePassword,
  reauthenticateWithCredential,
} from "firebase/auth";
import { AppContext } from "../../App";
import Alert from "./Alert";
import SignOut from "../../authentication/signOut";

function AddAccess({ action, gyms, data = null, closeModal }) {
  const [email, setEmail] = useState("");
  const [confirmEmail, setConfirmEmail] = useState("");
  const [position, setPosition] = useState("");
  const [access, setAccess] = useState([]);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [buttonText, setButtonText] = useState(action);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertColor, setAlertColor] = useState("green");
  const [selectedRole, setSelectedRole] = useState("Admin");
  const [showDropdown, setShowDropdown] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSingout, setIsSignout] = useState(false);
  const { appInfo, setAppInfo } = useContext(AppContext);
  const options = ["Admin", "Staff"];
  const navigate = useNavigate();

  useEffect(() => {
    if (data) {
      setEmail(data.email || "");
      setConfirmEmail(data.email || "");
      setPosition(data.position || "");
      const gymIdsWithAccess = data.gyms_with_access
        .split(", ")
        .map((gymName) => gyms.find((g) => g.name === gymName.trim())?.gym_id)
        .filter((id) => id !== undefined); // Ensure only valid IDs are set
      setAccess(gymIdsWithAccess);
      setSelectedRole(data.role_id === 2 ? "Admin" : "Staff");
    }
  }, [data, gyms]);

  const handleSelectionChange = (selectedNames) => {
    // Map selected gym names back to their gym_ids
    const selectedIds = selectedNames
      .map((name) => gyms.find((g) => g.name === name)?.gym_id)
      .filter((id) => id !== undefined);
    setAccess(selectedIds);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    const formData = {
      u_id: data?.u_id,
      user_name: "",
      email: email,
      gym_id:
        selectedRole === "Admin"
          ? gyms.map((gym) => gym.gym_id).filter((id) => id !== undefined)
          : access,
      role_id: selectedRole === "Admin" ? 2 : 3,
      position: position,
    };

    if (email !== confirmEmail) {
      setAlertMessage("Emails do not match");
      setAlertColor("red");
      setShowSuccessAlert(true);
      setIsLoading(false);
      return;
    }

    if (access.length === 0 && selectedRole === "Staff") {
      setAlertMessage("At least one gym must be selected to give access.");
      setAlertColor("red");
      setShowSuccessAlert(true);
      setIsLoading(false);
      return;
    }

    try {
      if (action === "Edit") {
        await updateAdminDetails(formData);
        if (
          appInfo.userInfo.u_id === data?.u_id &&
          selectedRole !== (data.role_id === 2 ? "Admin" : "Staff")
        ) {
          setIsSignout(true);
        }
        setAlertMessage("User Details updated successfully");
        setAlertColor("green");
        setShowSuccessAlert(true);
        setButtonText("OK");
        setIsLoading(false);
      } else if (action === "Add") {
        try {
          // const userInfo = await addUser(formData);
          // if (!userInfo.user.emailVerified) {
          await triggerEmail(formData);
          // }
          setAlertMessage("An invitation email has been sent to the user");
          setAlertColor("green");
          setButtonText("OK");
          setShowSuccessAlert(true);
          setIsLoading(false);
        } catch (error) {
          setAlertColor("red");
          setAlertMessage(
            "An error has occurred. Please contact info@redprintfit.com if the problem persists."
          );
          setShowSuccessAlert(true);
          setIsLoading(false);
          return;
        }
      }
    } catch (error) {
      setAlertColor("red");
      setAlertMessage(
        "An error has occurred. Please contact info@redprintfit.com if the problem persists."
      );
      setShowSuccessAlert(true);
      setIsLoading(false);
      return;
    }
  };

  const handleModalClose = () => {
    if (buttonText === "OK") {
      closeModal();
      if (action === "Edit") {
        if (isSingout) {
          SignOut(navigate, setAppInfo);
        } else {
          window.location.reload();
        }
      }
    }
  };

  const handlePositionChange = (event) => setPosition(event.target.value);
  const handleEmailChange = (event) => setEmail(event.target.value);
  const handleConfirmEmailChange = (event) =>
    setConfirmEmail(event.target.value);
  const handleRoleSelect = (role) => {
    setSelectedRole(role);
    setShowDropdown(false);
  };

  const updateAdminDetails = async (data) => {
    try {
      const body = {
        userName: data.user_name,
        email: data.email,
        gymIds: data.gym_id,
        roleId: data.role_id,
        position: data.position,
        org_id: appInfo?.org?.org_id,
      };
      const request = JSON.stringify(body);
      const response = await updateData(
        `gymUsers/${data.u_id}`,
        request,
        appInfo.token
      );
      if (!response.ok) {
        throw new Error(response.message || "Failed to update admin details");
      }

      console.log("Admin details updated successfully");
    } catch (error) {
      console.error("Error updating admin details:", error);
      setAlertColor("red");
      setAlertMessage(
        "An error has occurred. Please contact info@redprintfit.com if the problem persists."
      );
      setShowSuccessAlert(true);
      setIsLoading(false);
      return false;
    }
  };

  const addUser = async (data) => {
    try {
      const body = {
        name: data.user_name,
        email: data.email,
        gymIds: data.gym_id,
        roleId: data.role_id,
        position: data.position,
        orgId: appInfo.org.org_id,
        token: appInfo.token,
        organizationName: appInfo.org.name,
        password: data.password,
      };
      const request = JSON.stringify(body);

      let response = {};
      response = await addData(`gymUsers`, request, appInfo.token);
      if (!response.user.uid) {
        const errorData = await response.JSON();
        throw new Error(errorData.message || "Failed to add user");
      } else {
        console.log("User access added successfully");
        return response;
      }
    } catch (error) {
      throw new Error(error);
    }
  };

  const updateUser = async (data) => {
    try {
      const body = {
        gymIds: data.gym_id,
        roleId: data.role_id,
        position: data.position,
        orgId: appInfo.org.org_id,
        token: appInfo.token,
        organizationName: appInfo.org.name,
      };
      const request = JSON.stringify(body);
      let response = {};
      response = await updateData(
        `users/byProvider/${data.provider_id}`,
        request,
        appInfo.token
      );
      console.log("User access updated successfully");
    } catch (error) {
      throw new Error(error);
    }
  };

  const fetchUserByEmail = async (uEmail) => {
    try {
      const user = await getData(
        `users/byEmail?email=${uEmail}`,
        appInfo.token
      );
      return user;
    } catch (error) {
      console.error("Error fetching stats:", error);
    }
  };

  const triggerEmail = async (data) => {
    try {
      // console.log(data);
      const webHost = process.env.REACT_APP_WEB_URL;
      const newUser = await fetchUserByEmail(data.email);
      let redirectionUrl = null;
      if (!newUser.provider_id) {
        const response = await addUser(data);
        redirectionUrl = `${webHost}verifyEmail?emailToken=${response.emailToken}`;
      } else {
        data.provider_id = newUser.provider_id;
        await updateUser(data);
      }

      let body = {
        templateName: "account_auth.hbs",
        data: {
          senderName: appInfo.userInfo.name,
          user: data.user_name,
          organizationName: appInfo.org.name,
          isAdmin: data.role_id === 2,
          loginWithProviderUrl: `${webHost}`,
          loginWithEmailUrl: `${webHost}`,
        },
        subject: "Verify your email",
        toEmail: data.email,
      };

      if (redirectionUrl) {
        body.data.verifyEmailUrl = redirectionUrl;
      }
      body = JSON.stringify(body);
      const response = await addData(
        `/emails/triggerEmail`,
        body,
        appInfo.token
      );

      if (!response.message) {
        const errorData = await response.JSON();
        throw new Error(errorData.message || "Failed to send email");
      }
    } catch (error) {
      throw new Error(error);
    }
  };

  return (
    <>
      {showSuccessAlert && (
        <Alert
          message={alertMessage}
          timeout={9000}
          color={alertColor}
          onClose={() => setShowSuccessAlert(false)}
        />
      )}
      <form onSubmit={handleSubmit}>
        {" "}
        <div className="grid gap-6">
          <div className="grid grid-cols-3 items-center gap-8"></div>
          <div className="grid grid-cols-3 items-center gap-8">
            <label
              htmlFor="email"
              className="col-span-1 text-left block text-sm font-medium text-gray-900"
            >
              Email
            </label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={handleEmailChange}
              className="col-span-2 text-sm font-medium bg-gray-300 block"
              required
              disabled={action === "Edit"}
            />
          </div>
          {action === "Add" && (
            <div className="grid grid-cols-3 items-center gap-8">
              <label
                htmlFor="confirmEmail"
                className="col-span-1 block text-left text-sm font-medium text-gray-900 justify-start"
              >
                Confirm Email
              </label>

              <input
                type="email"
                id="confirmEmail"
                value={confirmEmail}
                onChange={handleConfirmEmailChange}
                className="col-span-2 text-sm font-medium bg-gray-300 block"
                required
              />
            </div>
          )}
          <div className="grid grid-cols-3 items-center gap-8">
            <label
              htmlFor="position"
              className="col-span-1 block text-left text-sm font-medium text-gray-900 justify-start"
            >
              Position
            </label>
            <input
              type="text"
              id="position"
              value={position}
              onChange={handlePositionChange}
              className="col-span-2 text-sm font-medium bg-gray-300 block"
              required
            />
          </div>
          <div className="grid grid-cols-3 items-center gap-8">
            <label
              htmlFor="role"
              className="col-span-1 block text-left text-sm font-medium text-gray-900 justify-start"
            >
              Access Level
            </label>
            <div className="relative">
              <button
                id="dropdownDefaultButton"
                onClick={() => setShowDropdown(!showDropdown)}
                className="text-black bg-gray-300 border-none font-medium rounded-lg text-sm px-5 py-1.5 text-center inline-flex items-center"
                type="button"
              >
                {selectedRole}
                <svg
                  className="w-2.5 h-2.5 ms-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 10 6"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 1 4 4 4-4"
                  />
                </svg>
              </button>
              {showDropdown && (
                <div
                  id="dropdown"
                  className="absolute z-10 bg-white divide-y divide-gray-100 rounded-lg shadow w-44"
                >
                  <ul
                    className="py-2 text-sm text-gray-700"
                    aria-labelledby="dropdownDefaultButton"
                  >
                    {options.map((option, index) => (
                      <li key={index}>
                        <p
                          className="block px-4 py-2 hover:bg-gray-100"
                          onClick={() => handleRoleSelect(option)}
                        >
                          {option}
                        </p>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </div>
          {selectedRole === "Staff" && (
            <div className="grid grid-cols-3 items-center gap-8">
              <label
                htmlFor="access"
                className="col-span-1 block text-left text-sm font-medium text-gray-900 justify-start"
              >
                Access to
              </label>
              <MultiSelectDropdown
                onSelectionChange={handleSelectionChange}
                preselectedValues={access.map(
                  (id) => gyms.find((gym) => gym.gym_id === id)?.name
                )}
                values={gyms.map((gym) => gym.name)}
              />
            </div>
          )}
          <div className="flex items-center justify-center gap-8">
            <button
              type="submit"
              onClick={handleModalClose}
              className={`border-none ${
                isLoading
                  ? "text-black bg-gray-400"
                  : "text-white bg-purple-900 hover:bg-red-500"
              } rounded-lg font-medium text-sm text-sm px-5 py-1.5 me-2 mb-2 mr-6`}
              disabled={isLoading}
            >
              {buttonText}
            </button>
          </div>
        </div>
      </form>
    </>
  );
}

export default AddAccess;
