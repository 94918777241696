import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  getAuth,
  updatePassword,
  signInWithEmailAndPassword,
} from "firebase/auth";
import Alert from "../modules/Settings/Alert";
import { getData, addData, updateData } from "../common/utils";

function ForgotPassword() {
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertColor, setAlertColor] = useState("green");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [tokenAvailable, setTokenAvailable] = useState(false);
  const [decryptedToken, setDecryptedToken] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  const decryptToken = async (emailToken) => {
    try {
      const decryptedToken = await getData(
        `crypto/decrypt?encryptedData=${emailToken}`
      );
      return decryptedToken;
    } catch (error) {
      throw new Error(error);
    }
  };

  const getUserInfo = async (email) => {
    try {
      const userInfo = await getData(`users/byEmail?email=${email}`);
      return userInfo;
    } catch (error) {
      throw new Error(error);
    }
  };

  const updateUserDetails = async (uid, emailToken, password, token) => {
    try {
      const body = JSON.stringify({
        emailToken: emailToken,
        password: password,
      });
      await updateData(`users/${uid}`, body, token);
      console.log("password reset success!");
    } catch (error) {
      console.log("Error updating password");
    }
  };

  const sendEmailVerification = async (name, email, emailToken) => {
    try {
      const webHost = process.env.REACT_APP_WEB_URL;
      let body = {
        templateName: "reset_password.hbs",
        data: {
          user: name,
          redirectionUrl: `${webHost}verifyEmail?emailToken=${emailToken}`,
        },
        subject: "Reset your password",
        toEmail: email,
      };
      body = JSON.stringify(body);
      await addData("emails/triggerEmail", body);
      console.log("Email sent!");
    } catch (error) {
      console.error("Error sending email", error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const searchParams = new URLSearchParams(location.search);
      if (searchParams.has("emailToken")) {
        const emailToken = searchParams.get("emailToken");
        try {
          const result = await decryptToken(emailToken);
          if (result.uid && result.email && result.password) {
            setTokenAvailable(true);
            setDecryptedToken(result);
          }
        } catch (error) {
          console.log("error:", error);
        }
      }
    };

    fetchData();
  }, [location.search]);

  const HandlePasswordReset = async (event) => {
    event.preventDefault();

    if (tokenAvailable) {
      if (password !== confirmPassword) {
        setAlertMessage("Passwords do not match.");
        setAlertColor("red");
        setShowSuccessAlert(true);
        return;
      }

      const auth = getAuth();

      const credential = await signInWithEmailAndPassword(
        auth,
        decryptedToken.email,
        decryptedToken.password
      );

      const { user } = credential;
      const token = user && (await user.getIdToken());

      try {
        await updatePassword(user, password);
        const userInfo = await getUserInfo(decryptedToken.email);
        await updateUserDetails(
          // it needs to be provider_id, as backend we are using provider_id to fetch user doc.
          userInfo.provider_id,
          userInfo.email_token,
          password,
          token
        );
        setAlertColor("green");
        setAlertMessage(
          "Password reset success! You will be redirected to the landing page shortly"
        );
        setShowSuccessAlert(true);
        setPassword("");
        setConfirmPassword("");
        setTimeout(() => {
          window.location.href = "/";
        }, 6000);
      } catch (error) {
        setShowSuccessAlert(true);
        setAlertMessage("Error updating user password");
        setAlertColor("red");
        console.log(
          "Error updating user password: ",
          error.code,
          error.message
        );
      }
    } else {
      const email = document.getElementById("email").value;
      const userInfo = await getUserInfo(email);
      try {
        await sendEmailVerification(
          userInfo.name,
          userInfo.email,
          userInfo.email_token
        );
        setAlertMessage("Email sent for password reset link");
        setAlertColor("green");
        setShowSuccessAlert(true);
        window.location.pathname = "";
      } catch (error) {
        setAlertMessage(
          "An error has occurred. Please contact info@redprintfit.com if the problem persists."
        );
        setAlertColor("red");
        setShowSuccessAlert(true);
      }
    }
  };

  return (
    <>
      {showSuccessAlert && (
        <Alert
          message={alertMessage}
          timeout={3000}
          color={alertColor}
          onClose={() => setShowSuccessAlert(false)}
        />
      )}
      <div className="flex justify-center items-center h-full">
        <div className="mx-auto max-w-screen-lg bg-white rounded-lg shadow-lg overflow-hidden">
          <form
            action="#"
            className="w-full p-2"
            style={{
              width: "400px",
              backgroundColor: "#ffffff",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              padding: "50px",
              height: "100%",
              textAlign: "center",
            }}
          >
            <h1 className="font-bold text-2xl">Reset Password</h1>
            {!tokenAvailable && (
              <input
                type="email"
                id="email"
                placeholder="Email"
                className="bg-gray-200 border py-3 px-4 my-2 w-full"
              />
            )}
            {tokenAvailable && (
              <>
                <input
                  type="password"
                  placeholder="New Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="bg-gray-200 border py-3 px-4 my-2 w-full"
                />
                <input
                  type="password"
                  placeholder="Confirm Password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  className="bg-gray-200 border py-3 px-4 my-2 w-full"
                />
              </>
            )}
            <div>
              <a
                href="#"
                onClick={() => (window.location.pathname = "")}
                className="text-blue-600"
              >
                Back to sign in
              </a>
            </div>
            <button
              onClick={HandlePasswordReset}
              className="bg-red-600 text-white font-bold px-4 py-2 rounded shadow hover:bg-[#4a0057] transition-colors duration-300 ease-in-out"
            >
              Reset
            </button>
          </form>
        </div>
      </div>
    </>
  );
}

export default ForgotPassword;
